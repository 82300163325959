<template>
    <div class="page-content">
        <h2 class="page-title">{{$store.state.language.psa.title}}</h2>
        <p v-html="$store.state.language.psa.p1"></p>
        <div class="pl-4">
            <ul>
                <li>
                    <p>
                        {{$store.state.language.psa.p2}}
                    </p>
                    <p>
                        {{$store.state.language.psa.p3}}
                    </p>
                </li>
                <li>
                    <p>{{$store.state.language.psa.p4}}</p>
                    <p>
                        {{$store.state.language.psa.p5}}
                    </p>
                </li>
                <li>
                    <p>
                        {{$store.state.language.psa.p6}}
                    </p>
                </li>
                <li>
                    <p>
                        {{$store.state.language.psa.p7}}
                    </p>
                </li>
                <li>
                    <p>
                        {{$store.state.language.psa.p8}}
                    </p>
                </li>
                <li>
                    <p>
                        {{$store.state.language.psa.p9}}
                    </p>
                </li>
                <li v-if="$store.state.language.psa.p10">
                    <p>
                        {{$store.state.language.psa.p10}}
                    </p>
                    <p>
                        {{$store.state.language.psa.p11}}
                    </p>
                    <p>
                        {{$store.state.language.psa.p12}}
                    </p>
                </li>
            </ul>
            <p>
                {{$store.state.language.psa.p13}}
            </p>
            <p>
                {{$store.state.language.psa.p14}}
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PSA'
}
</script>
<style scoped>
    .page-content{
        max-width: 1300px;
        margin: 100px auto;
        padding: 0px 25px;
    }
    .page-title{
        font-size: 40px;
        font-weight: 900;
        margin-bottom: 25px;
        letter-spacing: 5px;
        text-shadow: -2px 0px 0px rgb(251 188 17 / 0%);
    }
    .page-content p{
        color: rgba(29,29,36,.75);
        font-size: 19px;
        line-height: 30px;
        margin-bottom: 0;
    }
</style>